import Menu from '../../components/menu'
import Footer from '../../components/footer'

import s from './about.module.css'

function About() {
  return (
    <div className="content-wrapper">
      <div className="content-wrapper__flex">
        <div className="content-wrapper__side-menu-wrapper">
          <Menu />
        </div>
        <div className="content-wrapper__main-wrapper">
          <h1>Connections across topics</h1>

          <p>
            Crosslinks is the MIT study site for students, by students.
          </p>

          <p>
            When you're frustrated, tired of searching Google endlessly for answers &mdash; don't. Come here instead.
          </p>

          <h2>Six Facets of Learning</h2>

          <p>Crosslinks connects topics you learn as an undergrad at MIT. These connections are called crosslinks. If you're familiar with graph theory, you can think of Crosslinks as a graph: topics are nodes on the graph, and connections among topics are directed edges. Every topic page has aggregated links to good materials that MIT students have found helpful.</p>

          <h3>Learn</h3>
          <p>The Learn section contains links to material that helps you learn about the topic. Look here if you want to find examples, notes, solutions, etc.</p>

          <h3>Prepare</h3>
          The Prepare section contains prerequisite topics. For example, if you're having trouble understanding the Second fundamental theorem of calculus, you should probably go to the Prepare section and review what an Antiderivative is.

          <h3>Relate</h3>
          <p>The Relate section contains topics that are closely related, e.g. Bode plots and Nyquist plots.</p>

          <h3>Advance</h3>
          <p>
            The Advance section contains topics that 'come after' the current topic.
          </p>

          <h3>Apply</h3>
          <p>The Apply section points to links that show real-life, interesting applications of this topic.</p>

          <h3>Assess</h3>
          <p>The Assess section contains questions to help you assess your mastery and understanding of the topic.</p>


          <h3>Privacy</h3>
          <p>Crosslinks uses Google Analytics to collect data on user analytics. Like many services, Google Analytics uses first-party cookies to track pageviews and returning users without personally identifying any individual.</p>

          <p>
            We are a research group and of course don't sell your info to anyone. Whenever we publish analytics results, we don't reveal personally-identifiable data and only report on aggregate results, like mean or standard deviation.
          </p>

          <p>
            Like Wikipedia, we do publish the usernames of those who edit topics. This is to prevent annoymous trolling and to create a sense of pride and editorship within the community.
          </p>

        </div>
      </div>

      <Footer />
    </div>
  );
}

export default About;
