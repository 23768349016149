import { NavLink } from "react-router-dom";

import Menu from '../../components/menu'
import Footer from '../../components/footer'

import s from './faqs.module.css'

function FAQs() {
  return (
    <div className="content-wrapper">
      <div className="content-wrapper__flex">
        <div className="content-wrapper__side-menu-wrapper">
          <Menu />
        </div>
        <div className="content-wrapper__main-wrapper">
          <h1>FAQs</h1>
          <p className={s.question}>Does Crosslinks actually work? What's the theory behind it? </p>

          <p className={s.answer}>We have published a paper in The Bridge journal at the National Academy of Engineering: Miller, H., Willcox, K. and Huang, L., <a href="https://www.nae.edu/Publications/Bridge/162252.aspx" target="_blank" rel="noreferrer">Crosslinks: Improving Course Connectivity Using Online Open Educational Resources</a>, in The Bridge, The National Academy of Engineering, Volume 46, Number 3, pp. 38-44, Fall 2016.</p>

          <p className={s.question}>I'm confused about the six sections &mdash; what is Prepare, Learn, Relate, Advance, Apply and Assess? </p>

          <p className={s.answer}>These sections is a way of saying how topics are related to each other, and how you can learn them. Check out the detailed explanation <NavLink to={'/about'}>here</NavLink>.</p>

          <p className={s.question}>Is the material on Crosslinks accurate?</p>

          <p>The links you see here have been published by reputable sources; most of it comes from within MIT. The descriptions are either quoted directly from course material, Wolfram, Wikipedia or in a few cases, written directly by Prof. Haynes Miller or <a href="https://kiwi.oden.utexas.edu">Prof. Karen Willcox</a>.</p>

          <p className={s.question}>Something is wrong on the site. I have a suggestion!</p>

          <p>Please email us at <a href="mailto:mapping-lab@mit.edu">the MIT Mapping Lab</a>.</p>

          <p className={s.question}>I am an educator and I want to have my own Crosslinks for my own organization.</p>

          <p>The Crosslinks code library can be found <a href="https://github.com/wombats-writing-code/crosslinks-js" target="_blank">on Github</a>. If  you have any questions about using this library, you can email <a href="mailto:mapping-lab@mit.edu">the MIT Mapping Lab</a>.</p>
        </div>
      </div>

      <Footer />

    </div>
  );
}

export default FAQs;
