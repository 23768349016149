import _ from 'lodash'
import isEdge from './isEdge'
import getOutgoingPathAll from './getOutgoingPathAll'

const getOutgoingAll = (entity, mapData, nodesOnly=true) => {

  let path = getOutgoingPathAll(entity, mapData)

  // console.log('outgoing path:', path);

  let nodes = _.compact(_.map(path, pathItem => {
    if (!pathItem.relationship) {
      return pathItem.entity
    }

    if (nodesOnly) {
      if (pathItem.relationship && isEdge(pathItem.relationship, mapData)) {

        return pathItem.entity
      } else {
        return null
      }
    }

    return pathItem.entity
  }))



  // console.log('outgoing nodes:', nodes);


  return nodes
}

export default getOutgoingAll
