import { connect } from 'react-redux';

import TopicsComponent from './TopicsComponent';

import {getMapRequest} from '../../duck/actions'

import {changeSearchInput} from './duck/actions'

import {getTopics, getVisibleTopics, getSubjects} from './duck/selectors'



const mapStateToProps = (state, ownProps) => {
  return {
    map: state.data.map,
    getMapError: state.data.getMapError,
    isGetMapInProgress: state.data.isGetMapInProgress,

    searchInput: state.topics.searchInput,
    topics: getTopics(state),
    subjects: getSubjects(state, ownProps),
    visibleTopics: getVisibleTopics(state, ownProps)
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getMapRequest: () => dispatch(getMapRequest()),
    onChangeSearchInput: (value) => dispatch(changeSearchInput(value))
  }
};

const TopicsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TopicsComponent);



export default TopicsContainer
