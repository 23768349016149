import { connectRouter} from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { configureStore } from '@reduxjs/toolkit';
import dataReducer from './duck/'
import topicsReducer from './routes/topics/duck'
import menuReducer from './components/menu/duck'

export const history = createBrowserHistory()

const rootReducer = {
  data: dataReducer,
  topics: topicsReducer,
  menu: menuReducer,
  router: connectRouter(history)
}

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({immutableCheck: false}),
});
