
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from "react-router-dom";

import {getMapRequest} from '../../duck/actions'

import Footer from '../../components/footer'
import Menu from '../../components/menu'
import Viz from '../../components/viz'

import s from './main.module.css'

function Main() {
  const dispatch = useDispatch();

  const dataSlice = useSelector(state => state.data )
  const {map} = dataSlice

  useEffect( () => {
    if (!dataSlice.isGetMapInProgress && !dataSlice.map) {
      dispatch(getMapRequest())
    }
  }, [dataSlice, dispatch])

  let viz
  if (map) {
    viz = (
      <Viz map={map} />
    )
  }


  return (
    <div className="content-wrapper">
      <div className="content-wrapper__flex">
        <div className="content-wrapper__side-menu-wrapper">
          <Menu />
        </div>
        <div className="content-wrapper__main-wrapper">
          <div className={s.greeting}>
            <h1>MIT Crosslinks</h1>
            <p className={s['greeting__lead']}>The MIT study site.</p>
            <p className={s['greeting__lead']}>Authored by MIT students, for MIT students</p>
          </div>

          <p className="">Trying to figure something out? Check out topics from <NavLink to="/topics#calculus">Calculus</NavLink> to <NavLink to="/topics#biology">Biology</NavLink> to <NavLink to="/topics#algorithms">Algorithms</NavLink>!</p>


          <div className={s['viz-wrapper']}>
            {viz}
          </div>

        </div>

      </div>

      <Footer />
    </div>
  );
}

export default Main;
