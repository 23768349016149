import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types';

import downIcon from './assets/down-arrow.png'
import upIcon from './assets/up-arrow.png'

import s from './collapsible-assessment.module.css'



class CollapsibleAssessment extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      isCollapsed: true,
      isSolutionVisible: false
    }
  }

  componentDidMount() {

  }

  componentDidUpdate() {
    window.MathJax.typeset()
  }

  render() {
    let props = this.props

    let body
    if (!this.state.isCollapsed) {

      let solutionBlock
      if (this.state.isSolutionVisible) {
        solutionBlock = (
          <div>
            {props.item.solution.text}
          </div>
        )
      }

      body = (
        <div className={s['collapsible__body']}>
          {props.item.text}

          <ol className={s.choices}>
            {_.map(props.item.choices, (choice,idx) => {
              return (
                <li key={`choice__${idx}`} className={`choice ${s.choice}`}>
                  {choice.text}
                </li>
              )
            })}
          </ol>

          <p className={s['show-hide-solution']} onClick={() => this.setState({isSolutionVisible: !this.state.isSolutionVisible})}>
            {this.state.isSolutionVisible ? 'Hide' : 'Show'} solution
          </p>


          {solutionBlock}
        </div>
      )
    }

    // console.log(props.item);

    return (
      <div className={s.root}>
          <div className={s['collapsible__header']} onClick={() => this.setState({isCollapsed: !this.state.isCollapsed})}>
            <span>{props.item.displayName}</span>
            <img className={s['collapsible__header__icon']} src={this.state.isCollapsed ? downIcon : upIcon} alt=""/>
          </div>

          {body}
      </div>
    );
  }

}

CollapsibleAssessment.propTypes = {
  item: PropTypes.object.isRequired
}

export default CollapsibleAssessment
