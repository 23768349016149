import { NavLink } from "react-router-dom";


import mit from './assets/MIT_logo.png';
import s from './footer.module.css'

function FooterComponent() {
  return (
    <div className={s.footer}>
      <img className={s['mit-logo']} src={mit} alt="MIT"/>

      <div className={s.info}>
        <p>Crosslinks is the MIT study site, authored by MIT students, for MIT students.</p>
        <p>Development of Crosslinks supported in part by <a href="http://web.mit.edu/alumnifunds/awards/ay10-11.html" target="_blank">Alumni Class Funds</a>, the Lord Foundation and the MIT Office of Digital Learning.</p>

        <p>Last updated: <b>3/19/2021</b> at 11am</p>

      </div>
    </div>
  )

}


export default FooterComponent
