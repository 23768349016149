import _ from 'lodash'
import isEdge from './isEdge'
import getIncomingPathAll from './getIncomingPathAll'

const getIncomingAll = (entity, mapData, nodesOnly=true) => {

  let path = getIncomingPathAll(entity, mapData)

  // console.log('incoming path:', path);

  let nodes = _.compact(_.map(path, pathItem => {
    if (!pathItem.relationship) {
      return pathItem.entity
    }

    if (nodesOnly) {
      if (pathItem.relationship && isEdge(pathItem.relationship, mapData)) {

        return pathItem.entity
      } else {
        return null
      }
    }

    return pathItem.entity
  }))



  // console.log('incoming nodes:', nodes);


  return nodes
}

export default getIncomingAll
