import _ from 'lodash'


const getParent = (entity, data) => {

  let parentType = _.find(data.relationshipTypes, {parentType: true})

  let parents = _.compact(_.map( data.relationships, (r) => {

    if (r.sourceId === entity.id && r.type === parentType.name) {
      return _.find(data.entities, {id: r.targetId})
    }
  }));

  if (!parents) {
    return null
  }


  return parents[0];
}

export default getParent
