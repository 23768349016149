import { createSelector } from 'reselect'
import _ from 'lodash'

import graph from '../../../components/graph'



const getMap = (state, props) => {
  return state.data.map
}

const getSearchInput = (state, props) => state.topics.searchInput



export const getTopics = createSelector(
  [getMap],
  (map) => {
    if (!map) {
      return []
    }

    return _.filter(map.data.entities, {type: 'topic'})
  }
)



export const getSubjects = createSelector(
  [getMap],
  (map) => {
    if (!map) {
      return []
    }

    let topicParents = []
    let groupedbySubject = _.groupBy(_.filter(map.data.entities, {type: 'topic'}), topic => {
      let parent = graph.getParent(topic, map.data)

      topicParents.push(parent)

      return parent && parent.displayName
    })

    let subjects = _.compact(_.uniq(topicParents))

    return subjects
})


export const getVisibleTopics = createSelector(
  [getMap, getSearchInput],
  (map, searchInput) => {

    if (!map) {
      return []
    }

    // console.log('searchInput:', searchInput);

    let filteredTopics
    if (!searchInput) {
      filteredTopics = _.filter(map.data.entities, {type: 'topic'})

    } else {

      let words = searchInput.split(' ')
      let reStr = _.reduce(words, (result, word) => {
        result = result + '(?=.*\\b' + word + ')'

        return result
      }, '')

      reStr = reStr + '.+'

      let regex = new RegExp(reStr, 'gi')

      // console.log('regex:', regex);

      filteredTopics = _.filter(map.data.entities, e => {
        if (e.type !== 'topic') {
          return false
        }

        if (e.name.match(regex)) {
          // console.log('matched name:', e.name);

          return true
        }

        if (e.description.match(regex)) {
          // console.log('matched description:', e.name);

          return true
        }

        return false
      })
    }


    let topicParents = []
    let groupedbySubject = _.groupBy(filteredTopics, topic => {
      let parent = graph.getParent(topic, map.data)

      topicParents.push(parent)

      return parent && parent.displayName
    })

    // console.log('groupedbySubject:', groupedbySubject);

    let subjects = _.compact(_.uniq(topicParents))


    return groupedbySubject
  }
)
