import _ from 'lodash'

import params from './params'

const selectEdges = (map, nodes) => {
  // let links = _.filter(map.data.relationships, type => !type.parentType)
  let links = _.filter(map.data.relationships, {type: 'has prerequisite of'})

  links = _.compact(_.map(links, link => {

    let source = _.find(nodes, {id: link.sourceId})
    let target = _.find(nodes, {id: link.targetId})

    if (!source) {
      return null
    }

    if (!target) {
      return null
    }

    return _.assign(link, {
      source,
      target,
      strokeWidth: 1,
      stroke: params.link.stroke,
      opacity: params.link.opacity
    })
  }))

  return links
}


export {selectEdges}
