import _ from 'lodash'
import traversePath from './traversePath'
import getOutgoingPath from './getOutgoingPath'

const getIncomingPathAll = (entity, mapData, relTypes) => {
  if (!entity) {
    throw new Error('entity must be provided first argument of getIncomingRelationshipsAll')
  }

  let relationshipTypes;
  if (relTypes) {
    relationshipTypes = _.isArray(relTypes) ? relTypes : [relTypes];
  }

  let results = traversePath(entity, mapData, relationshipTypes, getOutgoingPath);

  return _.uniq(results);
}


export default getIncomingPathAll
