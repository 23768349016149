import _ from 'lodash'


import {
  GET_MAP_REQUESTED, GET_MAP_FAILURE, GET_MAP_SUCCESS
} from './actions'


const initialState = {
  map: null,
  isGetMapInProgress: false,
  getMapError: null,
}

function reducers(state = initialState, action) {
  switch (action.type) {
    case GET_MAP_REQUESTED:
      return _.assign({}, state, {
        isGetMapInProgress: true,
        getMapError: null
      })

    case GET_MAP_FAILURE:
      return _.assign({}, state, {
        isGetMapInProgress: false,
        getMapError: action.err
      })

    case GET_MAP_SUCCESS:
      return _.assign({}, state, {
        isGetMapInProgress: false,
        getMapError: null,
        map: action.map
      })


    default:
      return state
  }
}


export default reducers;
