import _ from 'lodash'

import params from './params'

const selectLabels = (nodes) => {

  // console.log('nodes in labels:', nodes);

  let labels = _.map(nodes, node => {
    return _.assign({}, node, {
      node,
      text: node.displayName || node.name,
      x: node.x,
      y: node.y,
      fill: params.label.fill,
      opacity: params.label.opacity,
      fontSize: params.label.fontSize
    })
  })

  return labels
}


export {selectLabels}
