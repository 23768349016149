import _ from 'lodash'
import { NavLink } from "react-router-dom";

import {getTopicSlug} from '../../routes/topics/utils'


import s from './topics-list.module.css'

function TopicsListComponent(props) {

  return (
    <div className={s.root}>
      <p className={s['results-summary']}>Showing <b>{_.flatten(_.values(props.groupedBySubject)).length}</b> of <b>{(props.topics || []).length}</b> topics</p>

      {_.map(props.groupedBySubject, (topics, name) => {
        return (
          <div className={`topic-group`} key={name} id={name}>

            <h3>{name}</h3>

            {_.map(topics, (topic, idx) => {
              return (
                <div key={`topic-${idx}`} className={s.topic}>
                  <NavLink to={`/topics/${getTopicSlug(topic)}`}>
                    {topic.name}
                  </NavLink>
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )

}


export default TopicsListComponent
