import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from "react-router-dom";

import TopicsList from '../topics-list'
import {getVisibleTopics, getTopics} from '../../routes/topics/duck/selectors'

import { changeSearchInput } from "./duck/actions";

import logo from './assets/crosslinks-logo.png';
import s from './menu.module.css'

function MenuComponent(props) {
  const dispatch = useDispatch();

  const searchInput = useSelector(state => state.menu.searchInput)
  const topics = useSelector(state => getTopics(state) )
  const visibleTopics = useSelector(state => getVisibleTopics(state))

  // console.log(searchInput);
  // console.log('visibleTopics', visibleTopics);

  let dropdownBlock
  if (searchInput && searchInput.length > 1) {
    dropdownBlock = (
      <div className={s['search__dropdown']}>
        <TopicsList groupedBySubject={visibleTopics} topics={topics} />
      </div>
    )
  }

  return (
    <div className={s.menu}>
      <img src={logo} className={s.logo} alt="Crosslinks" />

      <ul className={`${s['menu__links']} menu__links`}>
        <li>
          <NavLink exact to="/" activeClassName="is-active">
            Main
          </NavLink>
        </li>
        <li>
          <NavLink to="/topics" activeClassName="is-active">
            All topics
          </NavLink>
        </li>
        <li>
          <NavLink to="/about" activeClassName="is-active">
            About Crosslinks
          </NavLink>
        </li>
        <li>
          <NavLink to="/team" activeClassName="is-active">
            Team
          </NavLink>
        </li>
        <li>
          <NavLink to="/faqs" activeClassName="is-active">
            FAQs
          </NavLink>
        </li>
        {/* <li>
          <a>Edit history</a>
        </li>
        <li>
          <a>Add a Topic</a>
        </li> */}
      </ul>

      <input type="search" placeholder="Search for topics" className={s['search-topics']}
          value={props.searchInput} onChange={(e) => dispatch(changeSearchInput(e.target.value))} />

      {dropdownBlock}
    </div>
  )

}


export default MenuComponent
