import getParent from './getParent'
import getIncomingAll from './getIncomingAll'
import getOutgoingAll from './getOutgoingAll'
import getIncomingPath from './getIncomingPath'
import getIncomingPathAll from './getIncomingPathAll'
import getOutgoingPath from './getOutgoingPath'
import getOutgoingPathAll from './getOutgoingPathAll'

const graph = {
  getParent,
  getIncomingAll,
  getOutgoingAll,
  getIncomingPath,
  getIncomingPathAll,
  getOutgoingPath,
  getOutgoingPathAll
}


export default graph
