import _ from 'lodash'

import params from './params'
import graph from '../graph'

const d3 = require('d3')


export const MIN_RADIUS = 8
export const MAX_RADIUS = 45

let nodeRadiusScale = d3.scaleSqrt()
  .domain([1, 20])
  .range([MIN_RADIUS, MAX_RADIUS])


const selectNodes = ({map, nodeOnFocus, nodesToDraw}) => {

  /* handle on focus selection */
  if (nodeOnFocus) {
    let path = graph.getOutgoingPathAll(nodeOnFocus, map.data, _.filter(map.data.relationshipTypes, {name: 'has prerequisite of'}) )

    // console.log('outgoing path:', path);
    // console.log('prerequisite nodes:', _.map(path, 'entity.name'));

    return {
      nodes: _.uniq(_.compact(_.map(path, 'entity'))),
      edges: _.uniq(_.compact(_.map(path, 'relationship')))
    }
  }

  let nodes = nodesToDraw || _.filter(map.data.entities, {type: 'topic'})

  // console.log(nodes, nodes.length);

  nodes = _.map(nodes, n => {
    let path = graph.getIncomingPath(n, map.data, _.filter(map.data.relationshipTypes, {name: 'has prerequisite of'}))
    let numOutgoing = _.map(path, 'entity').length

    return _.assign(n, {
      id: n._id,
      fill: params.node.fill,
      opacity: params.node.opacity,
      r: nodeRadiusScale(numOutgoing)
    })
  })


  return nodes
}


export {selectNodes}
