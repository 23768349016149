

export const getTopicSlug = (topic) => {
  return topic.name.split(' ').join('--')
}


export const getTopicNameFromSlug = (slug) => {
  return slug.split('--').join(' ')
}
