const _ = require('lodash')


const getIncomingPath = (entity, data, relTypes) => {
  // let fnName = `getIncomingPath`;

  // console.log('[getIncomingPath] entity:', entity);
  // console.log('[getIncomingPath] data:', data);

  let results = _.map( data.relationships, (r) => {
    // console.log('r.sourceId:', r.sourceId);

    if (r.targetId === entity.id) {
      // console.log('found matching:', r);

      if (!relTypes) {
        return {
          entity: _.find(data.entities, {id: r.sourceId}),
          relationship: r
        }
      }

      // if relTypes is defined, filter only relationships that match one of the relationship types
      if (relTypes && _.find(relTypes, {name: r.type})) {
        return {
          entity: _.find(data.entities, {id: r.sourceId}),
          relationship: r
        }
      }
    }
  });

  results = _.filter(results, 'entity')

  let starting = {
    entity,
    relationship: null
  }
  results.unshift(starting)


  return results;
}

export default getIncomingPath
