import _ from 'lodash'
import React, { useEffect } from 'react';
import { NavLink, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

import Menu from '../../components/menu'


import {getMapRequest} from '../../duck/actions'
import {getTopicSlug, getTopicNameFromSlug} from '../topics/utils'

import Viz from '../../components/viz'
import Graph from '../../components/graph'
import CollapsibleAssessment from '../../components/collapsible-assessment'

import s from './topic-route.module.css'

import berkeley from './assets/berkeley.png'
import khan from './assets/khan_academy.png'
import lamar from './assets/lamar.png'
import mathlet from './assets/mathlet.png'
import mit from './assets/mit.png'
import mitx from './assets/mitx.png'
import nature from './assets/nature.png'
import ocw from './assets/ocw.png'
import other_resource from './assets/other_resource.png'
import uc_davis from './assets/uc_davis.png'
import wikipedia from './assets/wikipedia.png'
import wolfram from './assets/wolfram.png'
import youtube from './assets/youtube.png'

function getImageIcon(url) {

  if (url.indexOf('ocw.mit.edu') > -1) {
    return ocw
  } else if (url.indexOf('berkeley') > -1) {
    return berkeley
  } else if (url.indexOf('khan') > -1) {
    return khan
  } else if (url.indexOf('lamar') > -1) {
    return lamar
  } else if (url.indexOf('mathlet') > -1) {
    return mathlet
  } else if (url.indexOf('mitx') > -1) {
    return mitx
  } else if (url.indexOf('mit') > -1) {
    return mit
  } else if (url.indexOf('nature') > -1) {
    return nature
  } else if (url.indexOf('davis') > -1) {
    return uc_davis
  } else if (url.indexOf('wikipedia') > -1) {
    return wikipedia
  } else if (url.indexOf('wolfram') > -1) {
    return wolfram
  } else if (url.indexOf('youtube') > -1) {
    return youtube
  } else if (url.indexOf('mit') > -1) {
    return mit
  }


  return other_resource
}

function TopicRoute() {
  const dispatch = useDispatch();
  const dataSlice = useSelector(state => state.data )

  let {slug} = useParams();


  const {map} = dataSlice

  useEffect( () => {
    if (!dataSlice.isGetMapInProgress && !dataSlice.map && !dataSlice.isGetMapInError) {
      dispatch(getMapRequest())
    }

    if (map) {
      // console.log('typesetting...');

      window.MathJax.typeset()

    }
  }, [dataSlice, dispatch])



  let topicBody, viz
  if (map) {
    let topic = _.find(map.data.entities, {name: getTopicNameFromSlug(slug)})
    let outcomes = _.filter(map.data.entities, {type: 'outcome'})

    // console.log('outcomes:', outcomes);
    // console.log(_.uniq(_.map(outcomes, 'assessment_id')));


    let prerequisites = _.compact(_.map(map.data.relationships, r => {
      if (r.sourceId === topic.id && r.type === 'has prerequisite of') {
        let target = _.find(map.data.entities, {id: r.targetId})

        return target
      }
    }))

    let relates = _.compact(_.map(map.data.relationships, r => {
      if (r.type === 'is related to') {
        if (r.sourceId === topic.id) {
          return _.find(map.data.entities, {id: r.targetId})
        } else if (r.targetId === topic.id) {
          return _.find(map.data.entities, {id: r.sourceId})
        }
      }
    }))

    let advances = _.compact(_.map(map.data.relationships, r => {
      if (r.targetId === topic.id && r.type === 'has prerequisite of') {
        let source = _.find(map.data.entities, {id: r.sourceId})

        return source
      }
    }))

    let learnAndApply = _.compact(_.map(map.data.relationships, r => {
      if (r.targetId === topic.id && r.type === 'has parent of') {
        let source = _.find(outcomes, {id: r.sourceId})

        return source
      }
    }))

    // console.log('learn and apply:', learnAndApply);

    let learn = _.find(learnAndApply, {category: 'Learn'})
    let apply = _.find(learnAndApply, {category: 'Apply'})


    let assessments = _.filter(map.assessments, a => {
      return a.outcome == topic.id
    })

    let noAssessmentsBlock
    if (!assessments || assessments.length === 0) {
      noAssessmentsBlock = (
        <p className={s['no-assessments']}>There are not yet any practice assessments for this topic. We're working on it!</p>
      )
    }

    // console.log('assessments:', assessments);

    topicBody = (
      <>

        <div className={s['topic__brief']}>
          <h1>{topic.name}</h1>
          <p>{topic.description}</p>
        </div>


        {/* prepare section */}
        <div className="grid-x grid-margin-x">
          <div className={`cell small-12 medium-5 ${s.pillar}`}>
            <div className={s['pillar__header']}>
              <h3 className={s['pillar__title']}>Prepare</h3>
              <span className={s['pillar__help']}>?</span>
            </div>

            <div className={s["pillar__body"]}>
              {_.map(prerequisites, (e, idx) => {
                return (
                  <li key={`prepare__${idx}`} className={s['pillar__list-item']}>
                    <NavLink to={`/topics/${getTopicSlug(e)}`}>
                      {e.name}
                    </NavLink>
                  </li>
                )
              })}
            </div>
          </div>
        </div>
        {/* end prepare section */}

        {/* learn and relate section */}
        <div className="grid-x grid-margin-x">
          <div className={`cell small-12 medium-6 large-5 ${s.pillar}`}>
            <div className={s['pillar__header']}>
              <h3 className={s['pillar__title']}>Learn</h3>
              <span className={s['pillar__help']}>?</span>
            </div>

            <div className={s["pillar__body"]}>
              {_.map(learn.links, (link, idx) => {
                return (
                  <li key={`learn__${idx}`} className={s['pillar__list-item']}>
                    <img src={getImageIcon(link.url)} className={s['pillar__list-item__icon']} />
                    <a href={link.url} target="_blank">
                      {link.name}
                    </a>
                  </li>
                )
              })}
            </div>
          </div>

          <div className={`cell small-12 medium-6 large-offset-1 large-5 ${s.pillar}`}>
            <div className={s['pillar__header']}>
              <h3 className={s['pillar__title']}>Relate</h3>
              <span className={s['pillar__help']}>?</span>
            </div>

            <div className={s["pillar__body"]}>
              {_.map(relates, (e, idx) => {
                return (
                  <li key={`relate__${idx}`} className={s['pillar__list-item']}>
                    <NavLink to={`/topics/${getTopicSlug(e)}`}>
                      {e.name}
                    </NavLink>
                  </li>
                )
              })}
            </div>
          </div>
        </div>
        {/* end learn and relate section */}

        {/* advance and apply section */}
        <div className="grid-x grid-margin-x">
          <div className={`cell small-12 medium-6 large-5 ${s.pillar}`}>
            <div className={s['pillar__header']}>
              <h3 className={s['pillar__title']}>Advance</h3>
              <span className={s['pillar__help']}>?</span>
            </div>

            <div className={s["pillar__body"]}>
              {_.map(advances, (e, idx) => {
                return (
                  <li key={`advance__${idx}`} className={s['pillar__list-item']}>
                    <NavLink to={`/topics/${getTopicSlug(e)}`}>
                      {e.name}
                    </NavLink>
                  </li>
                )
              })}
            </div>
          </div>

          <div className={`cell small-12 medium-6 large-offset-1 large-5 ${s.pillar}`}>
            <div className={s['pillar__header']}>
              <h3 className={s['pillar__title']}>Apply</h3>
              <span className={s['pillar__help']}>?</span>
            </div>

            <div className={s["pillar__body"]}>
              {_.map(apply.links, (link, idx) => {
                return (
                  <li key={`apply__${idx}`} className={s['pillar__list-item']}>
                    <a href={link.url} target="_blank">
                      {link.name}
                    </a>
                  </li>
                )
              })}
            </div>
          </div>
        </div>
        {/* end advance and apply section */}

        {/* assess section */}
        <div className="grid-x grid-margin-x">
          <div className={`cell small-12 medium-10 large-8 ${s.pillar}`}>
            <div className={s['pillar__header']}>
              <h3 className={s['pillar__title']}>Assess</h3>
              <span className={s['pillar__help']}>?</span>
            </div>

            <div className={s["pillar__body"]}>
              {_.map(assessments, (item, idx) => {
                return <CollapsibleAssessment key={`assess_${idx}`} item={item} />
              })}
            </div>

            {noAssessmentsBlock}
          </div>
        </div>

      </>
    )

    let nodesIncoming = Graph.getIncomingAll(topic, map.data, true)
    // let nodesOutgoing = Graph.getOutgoingAll(topic, map.data, true)

    let nodes = _.uniq(nodesIncoming)

    // console.log('nodes in viz:', nodes);
    // console.log(_.map(nodes, 'name'));

    viz = (<Viz map={map} nodes={nodes} nodeOnHighlight={topic}></Viz>)
  }



  // console.log('topic-route', topic-route);

  return (
    <div className="content-wrapper">
      <div className="content-wrapper__flex">
        <div className="content-wrapper__side-menu-wrapper">
          <Menu />
        </div>
        <div className="content-wrapper__main-wrapper">
          {topicBody}


          <div className={s['canvas-wrapper']}>
            {viz}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopicRoute;
