const _ = require('lodash')


const  traversePath = (entity, data, relTypes, getNeighborsFn) => {
  if (!entity) {
    throw new Error('An entity must be provided.')
  }
  if (!getNeighborsFn) {
    throw new Error('A function must be provided to get neighbors')
  }

  let results = [];
  let haveChecked = [];
  let traverse = function traverse(pathItem) {
    // console.log('traversing pathItem:', pathItem);

    let ns = getNeighborsFn(pathItem.entity, data, relTypes);
    ns = ns.slice(1)  // the first element is always the pathItem itself

    // console.log('ns:', ns);


    results = results.concat(ns);
    for (let i=0; i<ns.length; i++) {
      let n = ns[i];
      if (haveChecked.indexOf(n.entity.id) === -1) {

        haveChecked.push(n.entity.id);
        traverse.bind(this)(n);
      }
    }
  }.bind(this)

  let starting = {
    entity,
    relationship: null
  }

  traverse(starting)
  // console.log('results before unshift:', _.cloneDeep(results));

  results.unshift(starting)

  return _.uniqWith(results, (a, b) => {
    if (a.relationship && b.relationship) {
      return (a.entity.id === b.entity.id) && (a.relationship.id === b.relationship.id)
    } else {
      return (a.entity.id === b.entity.id) && (a.relationship === b.relationship)
    }
  });
}

export default traversePath
