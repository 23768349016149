import Menu from '../../components/menu'
import Footer from '../../components/footer'

import karen from './assets/karen_willcox.jpg'
import haynes from './assets/haynes_miller.jpg'
import luwen from './assets/luwen_huang.jpg'

import s from './team.module.css'

function Team() {
  return (
    <div className="content-wrapper">
      <div className="content-wrapper__flex">
        <div className="content-wrapper__side-menu-wrapper">
          <Menu />
        </div>
        <div className="content-wrapper__main-wrapper">
          <h2>Crosslinks Team</h2>

          <p>Crosslinks was founded in 2006 with founding faculty <a href="https://kiwi.oden.utexas.edu">Karen E. Willcox</a> and Haynes Miller. Since then, we've had many contributors to help us grow.</p>

          <div className={s.people}>
              <div className={s.person}>
                <img className={s['person__image']} src={karen} alt="Karen Willcox"></img>
                <p className={s['person__name']}><a href="https://kiwi.oden.utexas.edu">Karen E. Willcox</a></p>
                <p className={s['person__title']}>Faculty</p>
              </div>

              <div className={s.person}>
                <img className={s['person__image']} src={haynes} alt="Haynes Miller"></img>
                <p className={s['person__name']}>Haynes Miller</p>
                <p className={s['person__title']}>Faculty</p>
              </div>

              <div className={s.person}>
                <img className={s['person__image']} src={luwen} alt="Luwen Huang"></img>
                <p className={s['person__name']}>Luwen Huang</p>
                <p className={s['person__title']}>Product Owner</p>
              </div>

          </div>

          <h2>Technology</h2>
          <p>Crosslinks is supported by the <a href="https://mapping.mit.edu">MIT Mapping Lab</a>. The topics, learning resources and assessments you see on Crosslinks are drawn from the Open Ed Graph API, an open-source API supported by the Mapping Lab.</p>

          <p>If you would like to make your own Crosslinks, check out the Crosslinks front-end code repository. This will let you stand up your own Crosslinks with your own topics.</p>

          <p>If you would like to use the data alone &mdash; the topics, assessments, learning resources &mdash; visit the <a href="https://mapping.mit.edu">MIT Mapping Lab</a> for more detail. </p>

          <h2>Previous group members</h2>
          <ul>
            <li>
              Jeff Merriman (Technology)
            </li>
            <li>
              Cole Shaw (Technology)
            </li>
            <li>
              Norm Wright (Technology)
            </li>
            <li>
              Czarina Lao (undergraduate researcher)
            </li>
            <li>
              Adarsh Jeewajee
              (Undergraduate researcher)
            </li>
            <li>
              Jenny Sul
              (Undergraduate researcher)
            </li>
            <li>
              Carmela Lao (undergraduate researcher)
            </li>
            <li>
              Katherine Nazemi (undergraduate researcher)
            </li>
            <li>
              Emma Nelson (undergraduate researcher)
            </li>
            <li>
              Peter Wilkins (technology)
            </li>
            <li>
              Heidi Burgiel (educational advisor)
            </li>
            <li>
              Danielle Hicks (undergraduate researcher)
            </li>
            <li>
              Chad Lieberman
            </li>
          </ul>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Team;
