import createFetch from '../components/createFetch'
import {apiPath, bucketId} from '../components/apiPath'

export const GET_MAP_REQUESTED = 'GET_MAP_REQUESTED'
export const GET_MAP_FAILURE = 'GET_MAP_FAILURE'
export const GET_MAP_SUCCESS = 'GET_MAP_SUCCESS'



const getMapRequested = () => {
  return {
    type: GET_MAP_REQUESTED
  }
}

const getMapFailure = (err) => {
  return {
    type: GET_MAP_FAILURE,
    err
  }
}

const getMapSuccess = (map) => {
  return {
    type: GET_MAP_SUCCESS,
    map
  }
}


export const getMapRequest = amount => dispatch => {

  // console.log('getting map');

  dispatch(getMapRequested())

  return createFetch(`${apiPath}/maps/${bucketId}`, {
    method: 'GET',
  })
  .then( res => {
    return res.json()
  })
  .then( map => {
    console.log('fetched map:', map);

    dispatch(getMapSuccess(map))
  })
  .catch( err => {
    console.log('error:', err);

    dispatch(getMapFailure({err}))

  })


};
