
export const CHANGE_SEARCH_INPUT = 'CHANGE_SEARCH_INPUT'



export const changeSearchInput = (value) => {
  return {
    type: CHANGE_SEARCH_INPUT,
    value
  }
}
