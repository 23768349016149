
import React from 'react';
import PropTypes from 'prop-types';

// import params from './params'
import {selectNodes} from './nodes'
import {selectEdges} from './edges'
import {selectLabels} from './labels'

import s from './viz.module.css'
import './viz.css'

const d3 = require('d3')


class Viz extends React.Component {

  constructor(props) {
    super(props)

    this.rootEl = React.createRef()
    this.svgEl = React.createRef()
    this.svg = null
    this.simulation = null
  }

  // shouldComponentUpdate() {
  //   return false
  // }

  componentDidMount() {
    let props = this.props

    // console.log('this.svgEl:', this.svgEl);

    let width = this.rootEl.current.offsetWidth
    let height = this.rootEl.current.offsetHeight


    let nodes = selectNodes({map: props.map, nodesToDraw: props.nodes, nodeOnHighlight: props.nodeOnHighlight})
    let links = selectEdges(props.map, nodes)
    let labels = selectLabels(nodes)

    let zoom = d3.zoom()
    .on('zoom', e => {
      console.log('e:', e);
      this.svg.attr("transform", e.transform)
    })


    /* create our d3 elements */
    this.svg = d3.select('.canvas')
      // .attr('viewBox', [0, 0, width, height])
      .append('g')


    this.simulation = d3.forceSimulation(nodes)

    this.simulation
    .force('center', d3.forceCenter(width/3, height/3))
    .force('charge', d3.forceManyBody().strength(-30))
    .force('collision', d3.forceCollide().radius(d =>  d.r*2 ))
    .force('link', d3.forceLink(links)
      .id(d => d.id)
      .distance(60))


    const link = this.svg
      .selectAll('.edge')
      .data(links)
      .enter()
      .append('line')
      .attr('class', 'edge')
      .attr('strokeWidth', d => d.strokeWidth)
      .attr('stroke', d => d.stroke.default)
      .attr('opacity', d => d.opacity.default)
      .attr("marker-start", "url(#requisiteMarker)")
      .attr('fill', d => d.stroke.default)


    const node = this.svg.selectAll('.node')
      .data(nodes)
      .enter()
      .append('circle')
      .attr('class', 'node')
      .attr('r', d => d.r)
      .attr('fill', d => d.fill.default)
      .attr('opacity', d => d.opacity.default)
      .on( 'mouseover', (e, d) => this._onNodeMouseover(e, d) )
      .on( 'mouseout', (e, d) => this._onNodeMouseover() )


    const label = this.svg.selectAll('.label')
      .data(labels)
      .enter()
      .append("text")
      .attr("class", "label")
      .text( d => d.text )
      .style("opacity", d => d.opacity.default )
      .style("fill", d => d.fill.default )
      .style("font-size", d =>  d.fontSize )

      // console.log('labels:', labels);



    this.simulation.on('tick', () => {
      node.attr('cx', d => d.x);
      node.attr('cy', d => d.y);

      link
      .attr("x1", function(d) { return d.source.x; })
      .attr("y1", function(d) { return d.source.y; })
      .attr("x2", function(d) { return d.target.x; })
      .attr("y2", function(d) { return d.target.y; });


      label
      .attr( "x", d =>  d.node.x + d.node.r + 1 )
      .attr( "y", d => d.node.y )
      // .style('font-size', d => params.label.fontSize * width / this.svgEl.current.getBBox().width )
    });

    this.svg.call(zoom)


    // setTimeout( () => {
    //   console.log(this.svgEl.current.getBBox());
    //
    //   d3.select(window).on("resize", e => {
    //     const newWidth = this.svgEl.current.getBBox().width
    //
    //     console.log(newWidth);
    //
    //     const newFontSize = params.label.fontSize * (width / parseInt(newWidth));
    //
    //     console.log('new font size:', newFontSize);
    //
    //     d3.selectAll(".label").select("text")
    //         .style("font-size", newFontSize)
    //   });
    //
    // }, 5000)


  }


  _onNodeMouseover = (e, node) => {
    if (node) {

      let path = selectNodes({map: this.props.map, nodeOnFocus: node})

      console.log('selected path:', path);


      /* make all other unrelated edges blank */
      this.svg.selectAll('.edge')
      .attr('stroke', d => (path.edges.indexOf(d) > -1) ? d.stroke.hover : d.stroke.inactive)
      .attr('opacity', d =>(path.edges.indexOf(d) > -1) ?  d.opacity.hover: d.opacity.inactive)

      /* make all other unrelated nodes blank */
      this.svg.selectAll('.node')
      .attr('fill', d => (path.nodes.indexOf(d) > -1) ? d.fill.hover : d.fill.inactive)
      .attr('opacity', d => (path.nodes.indexOf(d) > -1) ?  d.opacity.hover: d.opacity.inactive)


      /* make all other labels blank */
      this.svg.selectAll('.label')
      .style('fill', d => (path.nodes.indexOf(d.node) > -1) ? d.fill.hover : d.fill.inactive)
      .style('opacity', d => (path.nodes.indexOf(d.node) > -1) ?  d.opacity.hover : d.opacity.inactive)

    } else {

      this.svg.selectAll('.edge')
      .attr('stroke', d => d.stroke.default)
      .attr('opacity', d => d.opacity.default)

      this.svg.selectAll('.node')
      .attr('fill', d => d.fill.default)
      .attr('opacity', d => d.opacity.default)

      this.svg.selectAll('.label')
      .style('fill', d => d.fill.default)
      .style('opacity', d => d.opacity.default)
    }


  }




  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.map && prevProps.map !== this.props.map) {
    }

  }


  render() {
    let props = this.props

    console.log('props.map:', props.map);

    if (!props.map) {
      return null
    }

    return (
      <div className={s.root} ref={this.rootEl}>
        <svg id='viz' className={`canvas ${s.canvas}`} ref={this.svgEl}>
          <marker id="requisiteMarker" viewBox="-10 -5 10 10" refX="-40" refY="0" markerUnits="userSpaceOnUse" orient="auto" markerWidth="10" markerHeight="9" stroke="context-stroke">
      			<path className="marker-path" d="M0,-5 L-10,0 L0,5" fill="#999"></path>
      		</marker>
        </svg>
      </div>
    )
  }
}

Viz.propTypes = {
  map: PropTypes.object.isRequired,
  nodes: PropTypes.array,
  nodeOnHighlight: PropTypes.object,
}


export default Viz
