
import _ from 'lodash'


import {
  CHANGE_SEARCH_INPUT
} from './actions'


const initialState = {
  searchInput: '',
}

function reducers(state = initialState, action) {
  switch (action.type) {
    case CHANGE_SEARCH_INPUT:
      return _.assign({}, state, {
        searchInput: action.value,
      })

    default:
      return state
  }
}


export default reducers;
