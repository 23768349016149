import _ from 'lodash'
import React from 'react';
import PropTypes from 'prop-types'
import { NavLink } from "react-router-dom";

import Footer from '../../components/footer'
import Menu from '../../components/menu'

import graph from '../../components/graph/'

import TopicsList from '../../components/topics-list'

import s from './topics.module.css'


class Topics extends React.Component {

  componentDidMount() {
    this.props.getMapRequest()
  }


  render() {
    let props = this.props

    if (!props.map) {
      return (
        <div className="content-wrapper">
          <div className="content-wrapper__side-menu-wrapper">
            <Menu />
          </div>
        </div>
      )
    }

    // console.log('visibleTopics', props.visibleTopics);

    return (
      <div className="content-wrapper">
        <div className="content-wrapper__flex">
          <div className="content-wrapper__side-menu-wrapper">
            <Menu />
          </div>
          <div className="content-wrapper__main-wrapper">
            <div className={s.greeting}>
              <h1>Topics</h1>

              <input type="search" className={s.input} value={props.searchInput} onChange={(e) => props.onChangeSearchInput(e.target.value)} placeholder="Search by topic keywords, e.g. Hooke's law"/>

              <div className={s['subjects-filter']}>
                {_.map(props.subjects, (subject, idx) => {
                  return (
                    <p key={`subject-filter_${idx}`} className={s['subject-filter']}>
                      <a href={`#${subject.displayName}`}>{subject.displayName}</a>
                    </p>
                  )
                })}
              </div>

              <TopicsList groupedBySubject={props.visibleTopics} topics={props.topics} />

            </div>

          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

Topics.propTypes = {
  map: PropTypes.object,
  getMapRequest: PropTypes.func.isRequired,
  getMapError: PropTypes.object,
  isGetMapInProgress: PropTypes.bool.isRequired,

  searchInput: PropTypes.string.isRequired,
  onChangeSearchInput: PropTypes.func.isRequired,

  topics: PropTypes.array.isRequired,
  subjects: PropTypes.array.isRequired,
  visibleTopics: PropTypes.object.isRequired,
}

export default Topics;
