import _ from 'lodash'


function isLocal() {
  let location = window.location

  return location.host.indexOf('localhost') > -1
}

function createFetch(relativePath, providedOptions) {
  let location = window.location

  let serverUrl
  if (location.host.indexOf('localhost') > -1) {
    serverUrl = 'http://localhost:5000'
  } else if (location.host.indexOf('dev') > -1) {
    serverUrl = `https://mapping.mit.edu`
  } else {
    serverUrl = `https://mapping.mit.edu`
  }

  console.log('serverUrl:', serverUrl);

  let defaultHeaders = {
    Accept: 'application/json',
    // 'Authorization': 'Bearer ' + 's%3ARCUSdGgaN3tPLKKTxHde-Kc9PcSOGRiI.owJtRuzPSAoOuCcyGFxrH7M%2FFMfFRAFKqZrmfqgHGPU',
  }
  if (providedOptions && (providedOptions.method === 'POST' || providedOptions.method === 'PUT')) {
    defaultHeaders['Content-Type'] = 'application/json'
  }

  let options = {
    body: (providedOptions && providedOptions.body) || null,
    method: (providedOptions && providedOptions.method) || 'GET',
    mode: 'cors',
    // credentials: isLocal() ? 'include' : 'same-origin',
    headers: _.defaults(providedOptions ? providedOptions.headers : {}, defaultHeaders)
  }

  return fetch(`${serverUrl}${relativePath}`, options)
}


export default createFetch
