import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router' // react-router v4/v5
import { ConnectedRouter } from 'connected-react-router'

import 'foundation-sites/dist/css/foundation.css'
import './components/common.css'


import * as serviceWorker from './serviceWorker';
import {history} from './store'
import store from './store';

import Main from './routes/main'
import About from './routes/about'
import Team from './routes/team'
import FAQs from './routes/faqs'

import Topics from './routes/topics'
import TopicRoute from './routes/topic-route'


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConnectedRouter history={history}> { /* place ConnectedRouter under Provider */ }
        <Switch>
          <Route exact path="/" component={Main} />
          <Route path="/about" component={About} />
          <Route path="/team" component={Team} />
          <Route path="/faqs" component={FAQs} />
          <Route exact path="/topics" component={Topics} />
          <Route path="/topics/:slug" component={TopicRoute} />
        </Switch>
      </ConnectedRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
