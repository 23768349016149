import _ from 'lodash'

const isEdge = (relationship, mapData) => {

  let relType = _.find(mapData.relationshipTypes, {name: relationship.type})

  // console.log(relType);

  return !relType.parentType
}

export default isEdge
